// Variablen
$footer-font-size: 0.875rem;
$footer-max-width: 1200px;
$footer-padding: 16px;
$footer-heading-size: 1rem;
$mobile-breakpoint: 959px;

// Allgemeine Stile für den Footer
.footer {
  padding: $footer-padding;
  font-size: $footer-font-size;
}

// Stile für den Footer-Inhalt
.footer-content {
  display: flex;
  flex-direction: column; // Standardmäßig Spaltenlayout
  justify-content: space-between;
  max-width: $footer-max-width;
  margin: 0 auto;
  
  // Stile für Links
  a {
    text-decoration: none;
  }

  // Stile für Überschriften
  h3 {
    margin-top: 15px;
    font-size: 1.3rem;

    @media (min-width: #{$mobile-breakpoint + 1px}) {
      margin-top: 0; // Entfernt den oberen Abstand bei größeren Bildschirmen
    }
  }

  // Layout-Anpassungen für mobile und größere Bildschirme
  @media (min-width: #{$mobile-breakpoint + 1px}) {
    flex-direction: row; // Layout als Reihe für größere Bildschirme
  }
}

// Listenelemente
.footer-content li, .footer-list-item {
  padding: 0;
}

// Stile für div-Elemente im Footer
.footer-content div {
  margin: 0;
}

// Stile für Links im Footer
.footer-link {
  color: inherit; // Erbt die Textfarbe
  &:hover {
    text-decoration: underline; // Hover-Effekt
  }
}

// Footer-Spalten
.footer-column {
  margin-bottom: $footer-padding;
}

// Footer-Überschriften
.footer-heading {
  font-size: $footer-heading-size;
}

// Footer-Text
.footer-text {
  margin: 4px 0;
}
