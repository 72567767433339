// Definiere eine Variable für die primäre Schriftart, um sie bei Bedarf wiederzuverwenden
$font-family-body: "Moderustic", sans-serif;
$font-family-code: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

body {
  margin: 0;
  
  // Verwende -webkit und -moz Prefixes für bessere Browserkompatibilität bei der Schriftglättung
  -webkit-font-smoothing: antialiased; // Glättet die Schrift auf Webkit-basierten Browsern (z.B. Chrome, Safari)
  -moz-osx-font-smoothing: grayscale;  // Optimiert die Schriftanzeige auf macOS

  // Nutze die Schriftart-Variable für den Body-Text
  font-family: $font-family-body;

  // Automatische optische Anpassung der Schriftgröße basierend auf dem Font-Rendering
  font-optical-sizing: auto;
}

code {
  // Nutze die Variable für Schriftarten, die für Code genutzt werden
  font-family: $font-family-code;
}

.MuiLink-root {
  padding: 8px 16px !important; /* Erhöht die Größe des Touch-Targets */
}

.MuiLink-root {
  margin: 8px !important; /* Fügt Abstand zwischen den Links hinzu */
}
