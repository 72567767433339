// Variablen
$navbar-padding: 16px;
$navbar-bg-color: transparent;
$navbar-hover-bg-color: rgba(255, 255, 255, 0.1);
$navbar-hover-color: #99A91D;
$drawer-bg-color: white;
$drawer-hover-bg-color: #555;
$drawer-item-color: #555;
$icon-button-color: white;
$button-margin: 16px;

// Mobile Ansicht für die AppBar
@media (max-width: 800px) {
  .navbar-appbar {
    margin: 0; // Zentrierung für mobile Ansichten entfernen
    justify-content: flex-start; // Links ausrichten
    padding: 0 $navbar-padding; // Optional: Padding für besseren Abstand auf mobilen Geräten hinzufügen
  }
}

// Mobile Ansicht (bis 959px)
@media (max-width: 959px) {
  .navbar-appbar {
    margin: 0; // Zentrierung für mobile Ansichten entfernen
    justify-content: flex-start; // Links ausrichten
    padding: 0 $navbar-padding; // Optional: Padding für besseren Abstand auf mobilen Geräten hinzufügen
  }
}

// Desktop Ansicht ab 960px
@media (min-width: 960px) {
  .navbar-appbar {
    position: sticky; // Sticky Positionierung für die AppBar
    margin: 0 auto; // AppBar zentrieren
    justify-content: center; // Inhalt horizontal zentrieren
    align-items: center; // Inhalt vertikal zentrieren
  }
}

// Stile für Desktop-Navigation
.navbar-desktop {
  display: flex; // Flexbox-Layout verwenden
  justify-content: space-around; // Buttons gleichmäßig verteilen
  flex-grow: 1;
  width: 800px; // Breite für Desktop-Navigation
  max-width: 80%; // Maximale Breite als Prozentsatz des Containers
}

// Stile für mobile Navigation
.navbar-mobile {
  background-color: $navbar-bg-color !important; // Hintergrundfarbe transparent
  box-shadow: none !important; // Kein Schatten
}

// Stile für Drawer
.navbar-drawer-paper {
  background-color: $drawer-bg-color !important; // Hintergrundfarbe des Drawers
  color: black; // Textfarbe
}

// Stile für IconButtons im Drawer
.navbar-icon-button {
  color: $icon-button-color !important; // Icon-Farbe
}

// Stile für Buttons
.navbar-button {
  color: $icon-button-color !important; // Button-Farbe
  margin-left: $button-margin; // Abstand links
  margin-right: $button-margin; // Abstand rechts
  transition: background-color 0.3s, color 0.3s; // Sanfter Übergang für Hover-Effekte
}

// Hover-Effekt für Buttons auf Desktop
.navbar-button:hover {
  background-color: $navbar-hover-bg-color !important; // Hintergrundfarbe beim Hover
  color: $navbar-hover-color; // Textfarbe beim Hover
}

// Stile für Listenelemente im Drawer
.navbar-drawer-paper .MuiListItem-root {
  color: $drawer-item-color; // Textfarbe
}

// Hover-Effekt für Listenelemente im Drawer
.navbar-drawer-paper .MuiListItem-root:hover {
  background-color: $drawer-hover-bg-color !important; // Hintergrundfarbe beim Hover
  color: white; // Textfarbe beim Hover
}

// Stile für Drawer Icons
.navbar-drawer-icon {
  background-color: $navbar-bg-color !important; // Hintergrundfarbe für den aktiven Punkt
  margin-right: 6px; // Abstand rechts
}
