// Farben als Variable definieren für spätere Anpassungen
$dot-color: #99A91D;
$media-width: 960px;

.hero-text {
  font-size: 1.5rem !important;
}

.data-transfer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 20% 0;
  position: relative;
  background: transparent;
  overflow: visible;
}

.row {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 15px;
  position: relative;
  margin: 0;
}

// Datenpunkte Styling
.dot {
  width: 10px;
  height: 10px;
  background-color: $dot-color !important; // Verwende die vordefinierte Variable
  border-radius: 30%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
}

// Animationen für jede Reihe, Richtungen unterschiedlich
.row.first .dot {
  animation: moveDotsLeft 7s linear infinite;
}

.row.second .dot {
  animation: moveDotsRight 7s linear infinite;
}

.row.third .dot {
  animation: moveDotsLeft 7s linear infinite;
}

// Animation für Punkte, die sich nach links bewegen
@keyframes moveDotsLeft {
  0% {
    left: 100%;
    opacity: 0;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    left: -10px;
    opacity: 0;
    transform: scale(0.8);
  }
}

// Animation für Punkte, die sich nach rechts bewegen
@keyframes moveDotsRight {
  0% {
    left: -10px;
    opacity: 0;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    left: 100%;
    opacity: 0;
    transform: scale(0.8);
  }
}

// Anpassungen für mobile Geräte
@media (max-width: $media-width) {
  .data-transfer {
    transform: rotate(90deg);
    width: 55%;
  }

  .hero-text {
    font-size: medium !important;
  }
}