// Variablen
$hero-height: 100vh;
$hero-bg-gradient: linear-gradient(to bottom right, #EEF0D4, #FDF1F0);

// Stile für den Hero-Container
.hero-container {
  height: $hero-height; // Höhe des Containers auf die volle Höhe des Viewports setzen
  display: flex; // Flexbox-Layout verwenden
  align-items: center; // Inhalt vertikal zentrieren
  justify-content: center; // Inhalt horizontal zentrieren
  background: $hero-bg-gradient; // Hintergrund als Farbverlauf setzen
  position: relative; // Positionierung relativ zum übergeordneten Element
}
